@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
html,
body {
  border: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  font-family: 'Inter', sans-serif;
  background: #fafafa;
}
#root {
  height: 100%;
  background: #f4f5fa;
}
h1 {
  margin-top: 0px;
  font-size: 30px;
  line-height: 35px;
}

a {
  color: #000000;
  text-decoration: none;
}

.grid-container {
  min-height: 500px;
  height: 100vh;
  width: 100vw;
  display: grid;
  gap: 1px 1px;
  grid-template-areas:
    "login login"
    "login login"
    "login login";
}

.imagen {
  display: none;
  grid-area: imagen;
  background: url('https://res.cloudinary.com/grup-oliva-motor/image/upload/v1623832158/login-page-template/go-motor-fondo-oscuro_dc3evx.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  grid-area: login;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

.login-header {
  width: 100%;
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
}

.login-header img {
  display: block;
  margin: 0 auto;
}

.login-wrapper {
  width: 100%;
  order: 1;
  flex: 0 1 auto;
  align-self: flex-start;
}

.login-wrapper label {
  display: block;
  margin: 10px 0px;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}

.label-radio {
  display: inline;
  vertical-align: text-top;
  font-size: 14px;
}

.login-wrapper input[type=text],
.login-wrapper input[type=password] {
  width: 100%;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 17px 20px;
  font-size: 16px;
}

.login-wrapper input[type=text]:focus,
.login-wrapper input[type=password]:focus {
  border: 2px solid #000;
}

.login-wrapper input[type=password] {
  margin-bottom: 20px;
}

.remember-link {
  float: right;
  margin: 4px 0px;
  font-size: 14px;
}

.login-wrapper input[type=submit] {
  margin-top: 20px;
  background: #269c5c;
  border-radius: 4px;
  padding: 15px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.login-wrapper input[type=submit]:hover {
  background: #4faf7b;
}

.button {
  width: 100%;
  display: block;
  cursor: pointer;
}

.btn-google {
  margin-top: 8px;
  background: #2D3748;
  border-radius: 4px;
  padding: 15px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.btn-icon {
  vertical-align: text-bottom;
}

footer {
  order: 2;
  flex: 0 1 auto;
  align-self: center;
  margin-top: 70px;
  color: #616161;
}

/* .MuiAccordionSummary-content.Mui-expanded {
  border-bottom: 1px solid #dfdfdf;
} */

.MuiAccordionSummary-content.Mui-expanded h3 {
  color: #1976d2;
}

.MuiAccordionSummary-root .MuiTypography-h6 {
  transition: 0.2s color ease-in-out;
}

.MuiAccordionSummary-root .MuiTypography-h6:hover {
  color: #1976d2;
}

@media (min-width: 768px) {
  .login {
    width: 80%;
  }
  .login input[type=text],
  .login input[type=password] {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  body {
    background-color: transparent;
  }
  .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "imagen login"
    "imagen login"
    "imagen login";
  }
  .imagen {
    display: block;
  }
}

@media (min-width: 1200px) {}


@layer components {
  .ui-input {
    @apply px-2 py-1 placeholder-gray-300 text-gray-600 relative bg-white rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring w-full
  }
}