.notifyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notifyBox a {
  font-size: 0.8em;
  color: #a6a6a6;
}

.notifyBox a:hover {
  color: #333333;
}